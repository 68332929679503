import React from "react";
import { Box, Button, Typography, useMediaQuery } from "@material-ui/core";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStyles } from "../../index.styles";
import SanPham from "./SanPham";
import { graphql, useStaticQuery, Link } from "gatsby";

const ListSanPham = () => {
  const { allWpCategory } = useStaticQuery(graphql`
    query GetProductsQuery {
      allWpCategory {
        nodes {
          name
          description
          slug
          wpChildren {
            nodes {
              name
              id
              slug
            }
          }
          danh_muc {
            image {
              sourceUrl
            }
          }
        }
      }
    }
  `);

  const convertDataProducts = React.useMemo(() => {
    return allWpCategory?.nodes?.filter(
      item => item.wpChildren.nodes.length !== 0
    );
  }, [allWpCategory]);

  const classes = useStyles();
  const breakpointsXs = useMediaQuery("(max-width: 1000px)");
  const breakpoints = useMediaQuery("(max-width: 765px)");

  return (
    <Box bgcolor="#f5f5f5">
      <Box
        width={breakpointsXs ? "95%" : "80%"}
        margin="auto"
        py={breakpointsXs ? 4 : 8}
      >
        <Box
          display={!breakpoints ? "flex" : undefined}
          justifyContent="space-between"
          alignItems="center"
          mb={4}
        >
          <Box>
            <Typography variant="h4">
              <b>Sản Phẩm Tiêu Biểu</b>
            </Typography>
          </Box>
          <Box mt={breakpointsXs ? 1.5 : 0}>
            <Link to="/lien-he">
              <Button className={classes.buttonContact}>
                <Box color="white" display="flex" alignItems="center">
                  <Box mr={1.5}>
                    <FontAwesomeIcon icon={faPhoneAlt} />
                  </Box>
                  <b>LIÊN HỆ HỢP TÁC</b>
                </Box>
              </Button>
            </Link>
          </Box>
        </Box>
        {convertDataProducts?.map(item => (
          <Box className={classes.boxMain}>
            <Box width={breakpoints ? "100%" : "50%"} bgcolor="white">
              <img
                style={{ objectFit: "cover", width: "100%", height: "100%" }}
                src={item.danh_muc?.image?.sourceUrl || ""}
                alt=""
              />
            </Box>
            <Box
              width={breakpoints ? "100%" : "50%"}
              bgcolor="#e3edf3"
              display="flex"
              alignItems="center"
            >
              <SanPham
                title={item.description}
                list={item.wpChildren.nodes}
                slug={item.slug}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ListSanPham;
