import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  btnContact: {
    backgroundColor: "#308632",
    color: "white",
    transition: "0.5s all",
    "&:hover": {
      backgroundColor: "white",
      color: "#308632",
    },
    padding: "8px 10px",
  },
  btn2: {
    "&:hover": {
      backgroundColor: "#308632",
      borderColor: "#308632",
    },
    padding: "6px 10px",
    transition: "0.5s all",
  },
  boxMain: {
    height: "100%",
    zIndex: 2,
    backgroundColor: "rgb(0 0 0 /60%)",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(1000)]: {
      alignItems: "end",
      paddingBottom: 35,
    },
  },
  boxImg: {
    width: "100%",
    height: "100%",
  },
  exp: {
    [theme.breakpoints.up(1000)]: {
      position: "absolute",
      width: "70%",
      flexShrink: 0,
      top: -100,
      left: "30%",
    },
    paddingBottom: "15px",
    position: "relative",
  },
  content: {
    display: "flex",
    alignItems: "center",
    maxWidth: 400,
    minWidth: 150,
    backgroundColor: "#f5f5f5",
    borderLeft: "2px solid #308632",
    fontSize: 20,
    fontWeight: 700,
    padding: 32,
    [theme.breakpoints.up(1000)]: {
      fontSize: 34,
    },
    [theme.breakpoints.down(1000)]: {
      flexBasis: "45%",
    },
  },
  fullWidth: {
    width: "100%",
  },
}));
