import React from "react";
import { Box, Button } from "@material-ui/core";
import {
  faLongArrowAltRight,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStyles } from "../../index.styles";
import { navigate } from "gatsby";

const Product = ({ title, list, slug }) => {
  const classes = useStyles();

  return (
    <Box p="32px 52px">
      <Box fontSize={30} mb={3}>
        <b>{title}</b>
      </Box>
      {list.map(item => (
        <>
          <Box
            className={classes.link}
            onClick={() => navigate(`/san-pham/${item.slug}`)}
            display="flex"
            fontSize={18}
          >
            <Box mr={1.5} color="#308632">
              <FontAwesomeIcon icon={faArrowCircleRight} />
            </Box>
            {item.name}
          </Box>
        </>
      ))}
      <Box mt={3}>
        <Button
          onClick={() => navigate(`/danh-muc-san-pham/${slug}`)}
          className={classes.buttonViewDetail}
        >
          <b>XEM CHI TIẾT</b>
          <Box ml={1.5}>
            <FontAwesomeIcon icon={faLongArrowAltRight} />
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

export default Product;
