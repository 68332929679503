import React from "react";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import StopIcon from "@material-ui/icons/Stop";
import { machines } from "../../constants/constants";
import { StaticImage } from "gatsby-plugin-image";

const CoSoVatChat = () => {
  const breakpointsXs = useMediaQuery("(max-width: 1000px)");
  const breakpoints550 = useMediaQuery("(max-width: 550px)");

  return (
    <Box
      width={breakpointsXs ? "95%" : "80%"}
      margin="auto"
      py={breakpointsXs ? 0 : 4}
    >
      <Box mb={2}>
        <Typography variant="h4">
          <b>Cơ Sở Vật Chất - Máy Móc Thiết Bị</b>
        </Typography>
      </Box>
      <Box fontSize={18}>
        Công xưởng rộng hơn 10.000 m2 với đầy đủ các thiết bị máy móc hiện đại,
        tân tiến vận hành liên tục, với đội ngũ nhân công lành nghề luôn sẵn
        sàng phục vụ sản xuất, đảm bảo đúng tiến độ để giao hàng cho Quý khách
        hàng.
      </Box>
      <br></br>
      <Box my={2}>
        <Box fontSize={22}>
          <b>Danh sách các trang thiết bị máy móc :</b>
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap">
        {machines.map(item => (
          <Box display="flex" width={250}>
            <Box color="#308632" mr={0.5}>
              <StopIcon fontSize="small" />
            </Box>
            <Box fontWeight={700} fontSize={16}>
              {item.name}
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        mt={2}
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        style={{ gap: 10 }}
      >
        <Box
          overflow="hidden"
          width={breakpoints550 ? "48%" : "32%"}
          minWidth={160}
          mb={1}
        >
          <StaticImage
            className="image"
            src="../../images/coSoVatChatA.png"
            alt=""
          />
        </Box>
        <Box
          overflow="hidden"
          width={breakpoints550 ? "48%" : "32%"}
          minWidth={160}
          mb={1}
        >
          <StaticImage
            className="image"
            src="../../images/coSoVatChatB.png"
            alt=""
          />
        </Box>
        <Box
          overflow="hidden"
          width={breakpoints550 ? "48%" : "32%"}
          minWidth={160}
          mb={1}
        >
          <StaticImage
            className="image"
            src="../../images/coSoVatChatC.png"
            alt=""
          />
        </Box>
        <Box
          overflow="hidden"
          width={breakpoints550 ? "48%" : "32%"}
          minWidth={160}
          mb={1}
        >
          <StaticImage
            className="image"
            src="../../images/coSoVatChatD.png"
            alt=""
          />
        </Box>
        <Box
          overflow="hidden"
          width={breakpoints550 ? "48%" : "32%"}
          minWidth={160}
          mb={1}
        >
          <StaticImage
            className="image"
            src="../../images/coSoVatChatE.png"
            alt=""
          />
        </Box>
        <Box
          overflow="hidden"
          width={breakpoints550 ? "48%" : "32%"}
          minWidth={160}
          mb={1}
        >
          <StaticImage
            className="image"
            src="../../images/coSoVatChatF.png"
            alt=""
          />
        </Box>
        <Box
          overflow="hidden"
          width={breakpoints550 ? "48%" : "32%"}
          minWidth={160}
          maxHeight={299.5}
          mb={1}
        >
          <StaticImage
            className="image"
            src="../../images/coSoVatChatH.png"
            alt=""
          />
        </Box>
        <Box
          overflow="hidden"
          width={breakpoints550 ? "48%" : "32%"}
          minWidth={160}
          maxHeight={299.5}
          mb={1}
        >
          <StaticImage
            className="image"
            src="../../images/coSoVatChatI.png"
            alt=""
          />
        </Box>
        <Box
          overflow="hidden"
          width={breakpoints550 ? "48%" : "32%"}
          minWidth={160}
          mb={1}
        >
          <StaticImage
            className="image"
            src="../../images/coSoVatChatK.png"
            alt=""
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CoSoVatChat;
