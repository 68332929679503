import React from "react";
import { Box } from "@material-ui/core";

const TungNganhNghe = ({ title, detail }) => {
  return (
    <Box width="100%">
      <Box my={2.5}>
        <Box fontWeight={700} fontSize={25}>
          {title}
        </Box>
      </Box>
      <Box fontSize={14}>{detail}</Box>
    </Box>
  );
};

export default TungNganhNghe;
