import React from "react";
import { Box, useMediaQuery } from "@material-ui/core";
import NganhNghe from "./NganhNghe";
import { StaticImage } from "gatsby-plugin-image";
import ScrollAnimation from "react-animate-on-scroll";

const NganhNgheHoatDong = () => {
  const breakpoints = useMediaQuery("(max-width: 1000px)");
  const breakpointsXs = useMediaQuery("(max-width: 765px)");
  return (
    <Box bgcolor="#e3edf3">
      <Box
        width={breakpoints ? "95%" : "80%"}
        margin="auto"
        py={breakpoints ? 4 : 8}
      >
        <Box fontSize={34} fontWeight={700}>
          Ngành Nghề Hoạt Động
        </Box>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between">
          <Box width={breakpointsXs ? "100%" : "32%"} mt={4}>
            <ScrollAnimation animateOnce animateIn="animate__fadeInUp">
              <Box minHeight={300}>
                <StaticImage
                  style={{ width: "100%", height: 300 }}
                  src="../../images/coSoVatChatF.png"
                  alt=""
                />
              </Box>
              <NganhNghe
                title="Sản Xuất Giấy Kraft"
                detail="Giấy Kraft đang được ứng dụng rộng rãi trong nhiều lĩnh vực đời sống. Đặc biệt là sản xuất các loại thùng carton và làm các loại bao bì đóng gói sản phẩm như tô giấy đựng sản phẩm, ly giấy, túi giấy, hộp đựng hoa quả,vv..."
              />
            </ScrollAnimation>
          </Box>

          <Box width={breakpointsXs ? "100%" : "32%"} mt={4}>
            <ScrollAnimation
              animateOnce
              animateIn="animate__fadeInUp"
              delay={300}
            >
              <StaticImage
                style={{ width: "100%", height: 300 }}
                src="../../images/nganhNgheHoatDongB.png"
                alt=""
              />
              <NganhNghe
                title="Sản Xuất Phôi Tấm"
                detail="Phôi tấm 3, 5, 7 lớp là nguyên liệu làm thùng carton, làm để lót hàng hóa, lót cạnh của các đồ nội thất, làm vách ngăn hàng hóa, vv..."
                image="../../images/nganhNgheHoatDongB.png"
              />
            </ScrollAnimation>
          </Box>
          <Box width={breakpointsXs ? "100%" : "32%"} mt={4}>
            <ScrollAnimation
              animateOnce
              animateIn="animate__fadeInUp"
              delay={600}
            >
              <StaticImage
                style={{ width: "100%", height: 300 }}
                src="../../images/nganhNgheHoatDongC.png"
                alt=""
              />
              <NganhNghe
                title="Thùng Carton In Ấn, Gia Công"
                detail="Đội ngũ nhân công chuyên gia công các sản phẩm với độ khó cao và sự hỗ trợ của máy móc tạo ra sản phẩm hoàn chỉnh đạt tiêu chuẩn, chất lượng khi tới tay khách hàng."
                image="../../images/may_moc_thiet_bi/dan_song_5_lop.png"
              />
            </ScrollAnimation>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NganhNgheHoatDong;
