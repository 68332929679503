import React from "react";
import { Box, Button, Typography, useMediaQuery } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { useStyles } from "../../index.styles";
import TungTinTuc from "./TungTinTuc";
import { StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import { navigate, Link } from "gatsby";

const TinTucMoiNhat = () => {
  const classes = useStyles();
  const breakpointsXs = useMediaQuery("(max-width: 1000px)");
  const breakpoints = useMediaQuery("(max-width: 765px)");

  const listPost = useStaticQuery(
    graphql`
      query getNewestPost {
        allWpPost(sort: { fields: date, order: DESC }, limit: 3) {
          edges {
            node {
              id
              title
              date
              excerpt
              featuredImage {
                node {
                  sourceUrl
                }
              }
            }
          }
          totalCount
        }
      }
    `
  );

  return (
    <Box
      width={breakpointsXs ? "95%" : "80%"}
      margin="auto"
      py={breakpointsXs ? 4 : 8}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h4">
          <b>Tin Tức Mới Nhất</b>
        </Typography>
        {!breakpoints && (
          <Button
            onClick={() => {
              navigate("/tin-tuc");
            }}
            className={classes.buttonContact}
          >
            <Box color="white" display="flex" alignItems="center">
              <b>XEM THÊM</b>
              <Box ml={1.5}>
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </Box>
            </Box>
          </Button>
        )}
      </Box>
      <Box display="flex" flexWrap="wrap" justifyContent="space-evenly">
        <Box width={breakpoints ? "95%" : "48%"} mb={2}>
          <StaticImage
            src="../../images/tinTuc.png"
            alt=""
            layout="fullWidth"
          />
        </Box>
        <Box width={breakpoints ? "95%" : "48%"} ml={1}>
          {listPost?.allWpPost?.edges?.map(item => (
            <TungTinTuc
              date={item.node.date}
              title={item.node.title}
              detail={item.node.excerpt}
              id={item.node.id}
            />
          ))}
        </Box>
      </Box>
      {breakpoints && (
        <Box display="flex" justifyContent="center">
          <Box width="95%" minWidth={350}>
            <Link to="/tin-tuc">
              <Button className={classes.buttonContact}>
                <Box color="white" display="flex" alignItems="center">
                  <b>XEM THÊM</b>
                  <Box ml={1.5}>
                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </Box>
                </Box>
              </Button>
            </Link>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TinTucMoiNhat;
