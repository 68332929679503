import React from "react";
import { Box, Divider } from "@material-ui/core";
import { navigate } from "gatsby";
import dayjs from "dayjs";
import vi from "dayjs/locale/vi";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  title: {
    cursor: "pointer",
    "&:hover": {
      color: "#308632",
    },
  },
}));

const TungTinTuc = ({ date, title, detail, id }) => {
  const classes = useStyles();
  return (
    <Box mb={2}>
      <Box fontSize={14} color="#308632">
        Ngày đăng: {dayjs(date).locale(vi).format("DD/MM/YYYY Lúc HH:mm")}
      </Box>
      <Box
        className={classes.title}
        onClick={() => navigate(`/tin-tuc/${id}`)}
        fontSize={20}
        my={1}
      >
        <b>{title}</b>
      </Box>
      <Box
        dangerouslySetInnerHTML={{
          __html: detail,
        }}
        fontSize={14}
      ></Box>
      <Divider />
    </Box>
  );
};

export default TungTinTuc;
