import React from "react";
import { Box, Button, Typography, useMediaQuery } from "@material-ui/core";
import {
  faPhoneAlt,
  faRobot,
  faCheck,
  faBuilding,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LyDo from "./LyDo";
import { useStyles } from "../../index.styles";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ScrollAnimation from "react-animate-on-scroll";

const LyDoHopTac = () => {
  const breakpointsXs = useMediaQuery("(max-width: 1000px)");
  const breakpoints = useMediaQuery("(max-width: 765px)");
  const classes = useStyles();

  return (
    <Box>
      <Box
        width={breakpointsXs ? "95%" : "80%"}
        margin="auto"
        py={breakpointsXs ? 4 : 8}
      >
        <Typography variant="h4">
          <b>Lý Do Hợp Tác Với Công ty Trách Nhiệm Hữu Hạn 2-9</b>
        </Typography>
        <Box
          display={!breakpoints ? "flex" : undefined}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box width={breakpoints ? "100%" : "60%"} my={2}>
            <Box fontSize={18}>
              Với quy mô trên 10.000 m2 nhà xưởng. Công ty được đầu tư các máy
              móc, trang thiết bị kỹ thuật nhập khẩu từ nước ngoài, hoạt động
              theo dây chuyền công nghệ tiên tiến,nâng cao năng lực sản xuất,
              đáp ứng tối đa yêu cầu của Khách hàng.
            </Box>
          </Box>
          <Box mb={2}>
            <Link to="/lien-he">
              <Button className={classes.buttonContact}>
                <Box color="white" display="flex" alignItems="center">
                  <Box mr={1.5}>
                    <FontAwesomeIcon icon={faPhoneAlt} />
                  </Box>
                  <b>LIÊN HỆ HỢP TÁC</b>
                </Box>
              </Button>
            </Link>
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <Box width={breakpoints ? "100%" : "49%"}>
            <Box width="100%" mb={2.5}>
              <ScrollAnimation animateOnce animateIn="animate__fadeInLeft">
                <StaticImage
                  className={classes.fullWidth}
                  src="../../images/lyDoHopTacA.png"
                  alt=""
                />
              </ScrollAnimation>
            </Box>
            <Box mb={2.5}>
              <ScrollAnimation animateOnce animateIn="animate__fadeInLeft">
                <LyDo
                  icon={
                    <Box fontSize={25} color="white">
                      <FontAwesomeIcon icon={faCheck} />
                    </Box>
                  }
                  title="Sản phẩm có tính thẩm mỹ cao, chất lượng theo đúng cam kết"
                  detail="Đội ngũ lao động của Công ty khẳng định sẽ đáp ứng được tối đa các yêu cầu về thẩm mỹ và chất lượng của khách hàng đối với các sản phẩm của Công ty."
                />
              </ScrollAnimation>
            </Box>
            <Box mb={2.5}>
              <ScrollAnimation animateOnce animateIn="animate__fadeInLeft">
                <LyDo
                  icon={
                    <Box fontSize={25} color="white">
                      <FontAwesomeIcon icon={faBuilding} />
                    </Box>
                  }
                  title="Cơ sở sản xuất bao bì, giấy đã được khẳng định uy tín trên thị trường."
                  detail="Công ty 2-9 thành lập năm 2002 với 18 năm hoạt động trong lĩnh vực sản xuất bao bì carton. Thành công hôm nay không chỉ đến từ sự nỗ lực của tập teher ban lãnh đạ, CB, CNV Cty 2-9 mà còn nhờ sự tín nhiệm của đối tác, khách hàng thân thiết."
                />
              </ScrollAnimation>
            </Box>
          </Box>
          <Box width={breakpoints ? "100%" : "49%"}>
            {breakpoints && (
              <Box width="100%" mb={2.5}>
                <ScrollAnimation animateOnce animateIn="animate__fadeInRight">
                  <StaticImage
                    className={classes.fullWidth}
                    src="../../images/lyDoHopTacB.png"
                    alt=""
                  />
                </ScrollAnimation>
              </Box>
            )}
            <ScrollAnimation animateOnce animateIn="animate__fadeInRight">
              <LyDo
                icon={
                  <Box fontSize={25} color="white">
                    <FontAwesomeIcon icon={faRobot} />
                  </Box>
                }
                title="Dây chuyền công nghệ tiên tiến, máy móc hiện đại"
                detail="Với mục tiêu luôn phát triển bền vững đem đến cho khách hàng những sản phẩm thùng carton chất lượng, và đạt thẩm mỹ cao nhất Công ty khômng ngừng cập nhật ứng dụng công nghệ cao, hiện đại trong việc sản xuất."
              />
            </ScrollAnimation>
            <Box my={2.5}>
              <ScrollAnimation animateOnce animateIn="animate__fadeInRight">
                <LyDo
                  icon={
                    <Box fontSize={25} color="white">
                      <FontAwesomeIcon icon={faUser} />
                    </Box>
                  }
                  title="Đội ngũ lao động lành nghề giàu kinh nghiệm"
                  detail="Sản phẩm được hoàn thiện bằng bàn tay đội ngũ lao động lành nghề giàu kinh nghiệm cùng tinh thần làm việc nghiêm túc, nhiệt tình, đầy trách nhiệm. Đem đến khách hàng những giá trị tinh tế, chất lượng tốt nhất."
                />
              </ScrollAnimation>
            </Box>
            {!breakpoints && (
              <Box width="100%">
                <ScrollAnimation animateOnce animateIn="animate__fadeInRight">
                  <StaticImage
                    className={classes.fullWidth}
                    height={318}
                    src="../../images/lyDoHopTacB.png"
                    alt=""
                  />
                </ScrollAnimation>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LyDoHopTac;
