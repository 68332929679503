import React from "react";
import { Box } from "@material-ui/core";

const LyDo = ({ icon, title, detail }) => {
  return (
    <Box
      bgcolor="#e3edf3"
      borderRadius="0.3s"
      padding="30px 30px 10px 30px"
      minHeight={180}
    >
      <Box display="flex" alignItems="center">
        <Box
          width="48px"
          height="48px"
          lineHeight="48px"
          borderRadius="100%"
          bgcolor="#308632"
          textAlign="center"
        >
          {icon}
        </Box>
        <Box width="88%" mt={1} ml={2}>
          <Box fontSize={20}>
            <b>{title}</b>
          </Box>
          <Box fontSize={14} mt={1.5}>
            {detail}
          </Box>
        </Box>
      </Box>
      <Box my={2.5}></Box>
    </Box>
  );
};

export default LyDo;
