import React from "react";
import Seo from "../components/SEO/SEO";
import Layout from "../components/Layout/Layout";
import SlideBar from "../components/SlideBar/SlideBar";
import NganhNgheHoatDong from "../components/NganhNgheHoatDong/ListNganhNghe";
import LyDoHopTac from "../components/LyDoHopTac/LyDoHopTac";
import CoSoVatChat from "../components/CoSoVatChat/CoSoVatChat";
import SanPhamTieuBieu from "../components/SanPham/ListSanPham";
import TinTucMoiNhat from "../components/TinTucMoiNhat/TinTucMoiNhat";
import HoSoNangLuc from "../components/HoSoNangLuc/HoSoNangLuc";

const IndexPage = () => (
  <Layout>
    <Seo title="Trang chủ" />
    <SlideBar />
    <NganhNgheHoatDong />
    <LyDoHopTac />
    <CoSoVatChat />
    <SanPhamTieuBieu />
    {/* <DoiTac /> */}
    <TinTucMoiNhat />
    <HoSoNangLuc />
  </Layout>
);

export default IndexPage;
