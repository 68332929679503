import React from "react";
import { Box, Button, Typography, useMediaQuery } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { useStyles } from "../../index.styles";
import { StaticImage } from "gatsby-plugin-image";
import hoSoNangLuc from "../../../static/HSNL.pdf";

const HoSoNangLuc = () => {
  const classes = useStyles();
  const breakpointsXs = useMediaQuery("(max-width: 1280px)");
  const breakpoints = useMediaQuery("(max-width: 920px)");

  return (
    <Box bgcolor="#e3edf3">
      <Box width={breakpointsXs ? "95%" : "80%"} margin="auto" py={8}>
        <Box
          display={!breakpoints ? "flex" : undefined}
          justifyContent="space-evenly"
        >
          <Box width={breakpoints ? "100%" : "30%"}>
            <Typography variant="h4">
              <b>Hồ Sơ Năng Lực</b>
            </Typography>
            <Box mt={2} mb={3}>
              <Box fontSize={18}>
                Công ty trách nhiệm hữu hạn 2-9 trân trọng gửi đến quí khách
                hàng "Hồ Sơ Năng Lực" của công ty. Rất mong được hợp tác.
              </Box>
            </Box>
            <Button
              component="a"
              href={hoSoNangLuc}
              download
              className={classes.buttonViewDetail}
            >
              <b style={{ color: "white" }}>TẢI VỀ</b>
              <Box ml={1.5}>
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </Box>
            </Button>
          </Box>
          <Box
            width={breakpoints ? "100%" : "30%"}
            mt={2}
            bgcolor="rgb(0 144 73)"
            display="flex"
            alignItems="center"
          >
            <StaticImage
              src="../../images/hoSoNangLucA.png"
              alt=""
              className={classes.imgDocument}
            />
          </Box>
          <Box width={breakpoints ? "100%" : "30%"} mt={2}>
            <StaticImage
              src="../../images/hoSoNangLucB.png"
              alt=""
              layout="fullWidth"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HoSoNangLuc;
