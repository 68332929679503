import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Box, useMediaQuery } from "@material-ui/core";
import { useStyles } from "./slide-bar.styles";
import { useStaticQuery, graphql } from "gatsby";
import ScrollAnimation from "react-animate-on-scroll";
import img1 from "../../images/img1.jpg";

export default function Test() {
  const classes = useStyles();
  const breakpointsXs = useMediaQuery("(max-width: 1280px)");
  const breakpoints = useMediaQuery("(max-width: 1000px)");

  const { allWpMediaItem } = useStaticQuery(graphql`
    query getImg {
      allWpMediaItem(filter: { title: { eq: "bia" } }) {
        nodes {
          sourceUrl
          title
        }
      }
    }
  `);

  return (
    <>
      <Box>
        <img
          className={classes.boxImg}
          src={allWpMediaItem?.nodes?.[0]?.sourceUrl ?? img1}
          alt=""
        />
      </Box>

      <Box
        width={breakpointsXs ? "100%" : "80%"}
        margin="auto"
        py={breakpoints ? 0 : 4}
        display={!breakpoints ? "flex" : undefined}
        position={!breakpoints ? "relative" : undefined}
      >
        <Box width={breakpoints ? "100%" : "35%"} flexShrink={0}>
          <ScrollAnimation animateOnce animateIn="animate__fadeInUp">
            <StaticImage
              src="../../images/headerA.png"
              alt=""
              style={{ width: "100%" }}
            />
          </ScrollAnimation>
        </Box>
        <Box ml={breakpoints ? 0 : 8} p={breakpoints ? 2 : 0}>
          <ScrollAnimation animateOnce animateIn="animate__fadeInUp">
            <Box fontSize={breakpoints ? 24 : 34} fontWeight={700} mb={2}>
              Công ty trách nhiệm hữu hạn 2-9
            </Box>
            <Box fontSize={breakpoints ? 16 : 20}>
              Với hơn 20 năm hoạt động trong lĩnh vực sản xuất bao bì carton.
              Công ty chúng tôi tự hào là doanh nghiệp tư nhân của Việt Nam được
              đồng hành với nhiều thương hiệu lớn trong và ngoài nước. Hiện tại
              Công ty trách nhiệm hữu hạn 2-9 vẫn không ngừng nỗ lực cải tiến kỹ
              thuật, đầu tư dây chuyền, thiết bị hiện đại, đào tạo bồi dưỡng đội
              ngũ cán bộ công nhân viên để tạo nên những giá trị khác biệt trong
              lĩnh vực sản xuất bao bì carton, trở thành đối tác tin cậy, sự lựa
              chọn hàng đầu của các bạn hàng, đối tác trong nhiều lĩnh vực,
              ngành nghề.
            </Box>
          </ScrollAnimation>
        </Box>
      </Box>
    </>
  );
}
